// Import our CSS
import styles from '../css/app.pcss';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFacebookSquare, faTwitterSquare, faYoutubeSquare, faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {faChevronRight, faChevronLeft} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Accordion from "accordion/src/accordion.mjs";
import Tabs from 'vue-tabs-component';
import Glide, {Controls, Breakpoints, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';
import Colcade from "colcade";

library.add(faFacebookSquare, faTwitterSquare, faYoutubeSquare, faInstagram, faInfoCircle, faChevronRight, faChevronLeft);

// App main
const main = async () => {
	//
	// Async load the vue module
	const {default: Vue} = await import(/* webpackChunkName: "vue" */ 'vue');
	const glideCss = await import(/* webpackChunkName: "glide" */ '@glidejs/glide/dist/css/glide.core.css');

	Vue.component('font-awesome-icon', FontAwesomeIcon);
	Vue.use(Tabs);

	// Create our vue instance
	const vm = new Vue({
		el: "#app",
		components: {},
		data: {
			openMenu: (window.innerWidth >= 768) ? true : false,
		},
		computed: {
			showStatus: function() {
				if(window.sessionStorage.getItem('showStatus') === null) {
					return true;
				} else {
					return false;
				}
			}
		},
		methods: {
			closeStatus: function() {
				if(window.sessionStorage.getItem('showStatus') === null) {
					window.sessionStorage.setItem('showStatus', 'true')
					// Remove class on site-wrapper
					document.querySelector('.site-wrapper').classList.remove('with-status')
					// Remove status
					document.querySelector('.status__wrapper').remove()
				}
			},
			toggleMenu(e) {
				e.preventDefault();
				this.openMenu = !this.openMenu;
			},
			closeCookiePopup() {
				if(window.localStorage.getItem('cookiePopupClosed') === null) {
					window.localStorage.setItem('cookiePopupClosed', 'true')
					let popup = document.querySelector('.cookie__popup')
					popup.remove()
				}
			},
			startVideo(e) {

				let videoWrapper = e.currentTarget.parentNode
				videoWrapper.classList.add('video--active')

				let player
				let playerEl = videoWrapper.querySelector('.video__player')
				let videoId = playerEl.getAttribute('data-videoid')

				player = new YT.Player(playerEl, {
					height: 1028,
					width: 1920,
					videoId: videoId,
					events: {
						onReady: function(e) { console.log( e.target.playVideo() ) },
					}
				})

			}
		},
		mounted() {
			if(window.localStorage.getItem('cookiePopupClosed') === null) {
				let popup = document.querySelector('.cookie__popup')
				if(popup) {
					popup.style.display = 'block'
				}
			}
		},
	});

	return vm;
};

// Execute async function
main().then((vm) => {

	for (const el of document.querySelectorAll(".glide--general")) {
		new Glide(el).mount({Controls, Breakpoints, Swipe});
	}

	for (const el of document.querySelectorAll(".glide--hero")) {
		new Glide(el, {
			autoplay: 8000,
			hoverpause: false,
			animationDuration: 0
		}).mount({ Autoplay });
	}

	for (const el of document.querySelectorAll(".accordion")) {
		new Accordion(el, {
			onToggle: function (fold, isOpen) {
				var icon = fold.heading.querySelector('.fal');
				if (isOpen) {
					icon.classList.remove('fa-chevron-down');
					icon.classList.add('fa-chevron-up');
				} else {
					icon.classList.remove('fa-chevron-up');
					icon.classList.add('fa-chevron-down');
				}
			}
		});
	}

	// Do we need to init youtube video's?
	if (document.querySelectorAll(".video__player")) {

		var tag = document.createElement('script');
		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		console.log('YT initiated')
	}

	if(document.querySelectorAll('.photo--grid')) {

		for (const el of document.querySelectorAll(".photo--grid")) {
			new Colcade( el, {
				columns: '.grid-col',
				items: '.grid-item'
			});
		}

	}

});

